<template>
  <div>
    <AlertBox v-if="invitationQuota.event?.ticketCreationDeadlineHint && quotaConfig.showDeadlineExpirationHint" class="mb-5" type="info">
      <template #header> {{ t('EmailInvitationQuota.deadline.title') }} </template>
      {{ t('EmailInvitationQuota.deadline.description', { expiry_date: formattedExpiryDate }) }}
    </AlertBox>
    <AlertBox v-if="tickets && tickets.unlockedCount" class="mb-5" type="danger" :icon-path="mdiAlertOctagon">
      <template #header>{{ t('InvitationQuota.CrewQuota.invalidTickets.overview.title') }}</template>
      {{ t('InvitationQuota.CrewQuota.invalidTickets.overview.description') }}
    </AlertBox>
    <ContentCard>
      <div class="my-6">
        <h2 class="inline">{{ title }}</h2>

        <div class="flex flex-row justify-between items-center flex-wrap">
          <InternalQuotaStats :counts="props.counts.countData" :invitation-quota="props.invitationQuota" />

          <div class="flex flex-row gap-2 grow-0 mt-8">
            <DropDownSelect>
              <template #activator="{ open }">
                <button
                  class="btn-primary-purple-m"
                  :disabled="isLimitReached || (isDeadlineReached && quotaConfig.respectDeadline)"
                  @click.prevent="open"
                >
                  <SvgIcon :path="mdiPlusCircleOutline" :size="16" class="mr-2" />
                  {{ t('InvitationQuota.CrewQuota.createTicketButton') }}
                </button>
              </template>
              <template #default>
                <ul class="list-none p-0 m-0 w-[175px]">
                  <li
                    v-if="$can('sendSingleInvite', { for: invitationQuota })"
                    class="text-left py-2 px-2 cursor-pointer hover:bg-grey-100 divide-solid divide-y divide-grey-300"
                  >
                    <RouterLink :to="{ name: 'invitationQuotaCreateTicket', query: route.query }" class="text-black flex">
                      {{ t('InvitationQuota.CrewQuota.singleTicketButton') }}
                    </RouterLink>
                  </li>
                  <li
                    v-if="canUploadCsv"
                    class="text-left py-2 px-2 cursor-pointer hover:bg-grey-100 divide-solid divide-y divide-grey-300"
                  >
                    <RouterLink :to="{ name: 'invitationQuotaCsvUpload' }" class="text-black flex">
                      {{ t('InvitationQuota.CrewQuota.createCsvButton') }}
                    </RouterLink>
                  </li>
                </ul>
              </template>
            </DropDownSelect>

            <template v-if="quotaConfig.showExportButton">
              <DropDownSelect v-for="format in exportFormats" :key="`export-drop-down-${format}`">
                <template #activator="{ open }">
                  <button class="btn-ghost-purple-m" @click.prevent="open">
                    <SvgIcon :path="mdiTrayArrowDown" :size="16" class="mr-2" />
                    {{ t(`InvitationQuota.CrewQuota.exportButton.${format}`) }}
                  </button>
                </template>
                <template #default>
                  <ul class="list-none p-0 m-0 w-[175px]">
                    <li
                      v-for="filter in exportFilterValues"
                      :key="`filter_${filter}`"
                      class="text-left py-2 px-2 cursor-pointer hover:bg-grey-100 divide-solid divide-y divide-grey-300"
                    >
                      <a
                        :href="invitationQuotaTicketsDownloadUrl(toPlainId(props.invitationQuota.id) as string, filter, locale, format)"
                        class="text-black flex"
                      >
                        {{ t(`InvitationQuota.CrewQuota.${filter}`) }} ({{ props.counts.countData[filter] }})
                      </a>
                    </li>
                  </ul>
                </template>
              </DropDownSelect>
            </template>

            <MyOmrLink
              v-if="showRequestFormButton"
              :href="`/ticket_request_form/${toPlainId(props.invitationQuota.id)}`"
              class="btn-ghost-purple-m"
            >
              <SvgIcon :path="mdiFileDocumentOutline" :size="16" class="mr-2" />
              {{ t('InvitationQuota.CrewQuota.requestFormButton') }}
            </MyOmrLink>
          </div>
        </div>
      </div>
    </ContentCard>

    <ContentCard class="mt-6">
      <TabBar>
        <NavTabBarItem class="relative pr-2" :to="{ query: { tab: 'activated' } }" :is-selected="props.currentActiveTab == 'activated'">
          {{ t('InvitationQuota.CrewQuota.activatedTab') }}
        </NavTabBarItem>
        <NavTabBarItem
          v-if="showRequestedTickets"
          class="relative pr-2"
          :to="{ query: { tab: 'requests' } }"
          :is-selected="props.currentActiveTab == 'requests'"
        >
          {{ t('InvitationQuota.CrewQuota.requestedTab') }}
          <span class="chip-grey absolute top-[13px] right-0 mr-3">
            {{ counts.countData.requested }}
          </span>
        </NavTabBarItem>
        <NavTabBarItem
          v-if="showDeactivationFeature"
          class="relative pr-2"
          :to="{ query: { tab: 'deactivated' } }"
          :is-selected="props.currentActiveTab == 'deactivated'"
        >
          {{ t('InvitationQuota.CrewQuota.deactivatedTab') }}
        </NavTabBarItem>
      </TabBar>
      <HorizontalDivider class="-mt-[1px]" />
      <div class="mt-4 flex flex-row gap-2 items-center">
        <div class="border rounded border-grey-700 p-2 inline-flex flex-row gap-2">
          <SvgIcon :path="mdiMagnify" :size="24" class="text-grey-700" />
          <input
            v-model="searchQuery"
            type="text"
            class="outline-none"
            :placeholder="String(t('search'))"
            data-test-id="tickets-search-field"
          />
        </div>

        <DropDownSelect>
          <template #activator="{ open }">
            <button class="flex p-2 border border-solid rounded border-grey-700 items-center" @click.prevent="open">
              <SvgIcon :path="mdiFilterVariant" :size="24" class="text-grey-700 mr-2" />
              {{ statusFilterLabel }}
            </button>
          </template>

          <template #default="{ close }">
            <ul class="list-none p-0 m-0">
              <li v-for="option in filterOptions" :key="`filter_${option.value}`" class="separate-rows">
                <label class="block cursor-pointer">
                  <input v-model="status" type="radio" :value="option.value" class="peer absolute opacity-0 pointer-events-none" />
                  <div class="hover:bg-victoria-100 peer-checked:bg-victoria-100 py-2 px-3" @click="close">{{ option.text }}</div>
                </label>
              </li>
            </ul>
          </template>
        </DropDownSelect>

        <div class="grow"></div>

        <span v-if="selectedTicketIds.length" class="text-body-s-medium" data-test-id="selected-tickets-info">
          {{ t('EmailInvitationQuota.inviteesSelected', { count: selectedTicketIds.length }) }}
        </span>
        <InternalQuotaActionButtons
          :current-active-tab="currentActiveTab"
          :invitation-quota="invitationQuota"
          :is-deadline-reached="isDeadlineReached"
          :is-limit-reached="isLimitReached"
          :tickets="tickets"
          :selected-ticket-ids="selectedTicketIds"
          @change-ticket-selection="handleTicketsSelectionChange"
          @refetch="refetch"
        />
      </div>
      <InternalQuotaTicketsTable
        v-if="tickets"
        v-model="selectedTicketIds"
        :current-active-tab="props.currentActiveTab"
        :show-status="showStatus"
        :selected-ticket-ids="selectedTicketIds"
        :tickets="tickets.nodes"
        @refetch="refetch"
        @change-ticket-selection="handleTicketsSelectionChange"
      />

      <div v-if="hasTickets" class="py-4 w-full flex justify-center items-center gap-2">
        <PaginationControl
          class="my-4"
          :show-items-per-page="true"
          :per-page="props.perPage"
          :total="tickets?.totalCount"
          show-pagination="always"
          :show-total="true"
          :page-info="tickets?.pageInfo"
          @update:pagination-query-variables="emit('update:paginationQueryVariables', $event)"
          @update:per-page="emit('update:perPage', $event)"
        />
      </div>
    </ContentCard>

    <slot></slot>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'

import getFilterOptions, { type FilterStatusOption } from './filters'
import { type TicketsQuery, type InvitationQuotaQuery } from '@/gql/myomr'
import { useI18n } from 'vue-i18n'
import { mdiPlusCircleOutline, mdiAlertOctagon, mdiMagnify, mdiFilterVariant, mdiTrayArrowDown, mdiFileDocumentOutline } from '@mdi/js'
import { $can } from '@/services/roles'
import type { PaginationQueryVariables } from '@/components'
import type { TabEnum } from './activeTab'
import type { TicketCounts } from './ticketCounts'
import { toPlainId } from '@/helpers/globalIdHelper'
import useQuotaConfig, { type InternalQuotaTypeEnum } from './quotaConfig'
import { useRoute } from 'vue-router'
import { invitationQuotaTicketsDownloadUrl, exportFormats } from '@/services/ApiService'

const props = defineProps<{
  counts: TicketCounts
  currentActiveTab: TabEnum
  filterStatus: FilterStatusOption
  invitationQuota: InvitationQuotaQuery['invitationQuota']
  isLimitReached: boolean
  perPage: number
  searchQuery?: string
  tickets: TicketsQuery['tickets']
}>()

const { locale, t } = useI18n()
const route = useRoute()
const showStatus = computed(() => props.currentActiveTab == 'activated')

const quotaConfig = useQuotaConfig(props.invitationQuota.kind as InternalQuotaTypeEnum)

const showRequestedTickets = computed(() => props.invitationQuota.showFormLink)

const canUploadCsv = computed(() => $can('uploadCsv', { for: props.invitationQuota }) && quotaConfig.value.hasCsvUpload)

const showDeactivationFeature = computed(() => quotaConfig.value.showDeactivationFeature)

const isDeadlineReached = computed(() => {
  if (!props.invitationQuota.expirationDate) return false
  return new Date(props.invitationQuota.expirationDate).getTime() < new Date().getTime()
})
const formattedExpiryDate = computed(
  () => new Date(props.invitationQuota.event?.ticketCreationDeadlineHint as string).toLocaleDateString('de-DE') || null,
)

const exportFilterValues = ['activated', 'requested', 'deactivated'] as const

function handleTicketsSelectionChange(newValue: string[]) {
  selectedTicketIds.value = newValue
}

const emit = defineEmits<{
  (e: 'refetch', value: boolean): void
  (e: 'update:paginationQueryVariables', value: PaginationQueryVariables): void
  (e: 'update:perPage', value: number): void
  (e: 'update:searchQuery', value: string | undefined): void
  (e: 'update:filterStatus', value: FilterStatusOption): void
}>()

const searchQuery = computed({
  get: () => props.searchQuery,
  set: (newValue) => emit('update:searchQuery', newValue),
})

const refetch = (showSuccessModal = false) => emit('refetch', showSuccessModal)

const selectedTicketIds = ref<string[]>([])

const filterOptions = getFilterOptions()
const status = computed({
  get: () => props.filterStatus,
  set: (newValue) => emit('update:filterStatus', newValue),
})
const statusFilterLabel = computed(() => filterOptions.value.find((option) => option.value == props.filterStatus)?.text)

watch(
  () => props.tickets,
  () => {
    selectedTicketIds.value = []
  },
)

const title = computed(() => {
  return props.invitationQuota.title || t('EmailInvitationQuota.defaultTitle')
})

const hasTickets = computed(() => props.tickets?.nodes?.length)

const showRequestFormButton = computed(() => props.invitationQuota.showFormLink)
</script>
